import { Outlet } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="fixed bottom-0 left-0 w-full bg-[#000d1d] text-white py-2">
            <div className="container mx-auto text-center">
                <p className="text-sm">
                    <a href="https:\/\/github.com/seandiggity/privacysafe-locker" target="_blank" style={{color: '#6ec1e4'}}>Source Code</a>
                    <span style={{color: '#ffffff'}}> | </span> <a href="mailto:support@privacysafe.net" id="contact" target="_blank" style={{color: '#6ec1e4'}}>Found A Bug?</a>
                    <span style={{color: '#ffffff'}}> | </span> <a href="mailto:abuse@privacysafe.net" id="contact" target="_blank" style={{color: '#6ec1e4'}}>Report Abuse</a>
                    <span style={{color: '#ffffff'}}> | </span> <a href="https:\/\/privacysafe.app/about/privacysafe-tools" id="contact" target="_blank" style={{color: '#fb521f'}}>How Does It Work?</a>
                </p>
            </div>
        </footer>
    );
}

const RootLayout = () => {
    return (
        <div>
            <Outlet />
            <Footer />
        </div>
    );
}

export default RootLayout;
